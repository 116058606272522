<template>
    <v-card :loading="loading">
        <template slot="progress">
        <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
        </template>

        <v-img height="100%" src="https://tacoselguero.mx/wp-content/uploads/2021/05/guero.jpeg"></v-img>

        <v-card style="border-radius:15px 15px 0px 0px; margin-top:-20px; background-color:#f2f2f2!important;" :loading="loading">

            <v-tabs background-color="#f2f2f2" v-model="tab" centered icons-and-text>
                <v-tabs-slider></v-tabs-slider>

                <v-tab>
                    Iniciar Sesión
                    <v-icon small>mdi-login-variant</v-icon>
                </v-tab>

                <v-tab>
                    Registrarse
                    <v-icon small>mdi-account</v-icon>
                </v-tab>
                
                <v-tab-item>
                    <v-card flat>
                        <v-form class="px-4 py-6 mt-8">
                            <v-text-field 
                                :rules="[rules.required]"
                                @keydown.enter="login" 
                                label="Usuario | Correo Electrónico" 
                                name="login" 
                                v-model="user.email" 
                                outlined
                                rounded
                            ></v-text-field>
                            <v-text-field 
                                :rules="[rules.required]"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show1 ? 'text' : 'password'"
                                name="password"
                                label="Contraseña"
                                outlined
                                rounded
                                @click:append="show1 = !show1"
                                @keydown.enter="login" v-model="user.password" 
                            ></v-text-field>
                        </v-form>
                        <div>
                            <a style="margin: auto; display: table;">¿Olvidaste tu contraseña?</a>
                        </div>
                        <div class="px-4 py-6">
                            <v-btn rounded block color="#093B99" dark type="submit" @click="login" class="btn btn-primary px-4"><strong>Acceder</strong></v-btn>
                            <br>
                            <v-btn rounded block color="#093B99" outlined dark type="submit" @click="login" class="btn btn-primary px-4" :to="{ path: '/'}"><strong>Comprar como Invitado</strong></v-btn>
                        </div>
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card flat>
                        <v-form class="px-4 py-6 mt-8">
                            <v-text-field 
                                :rules="[rules.required]"
                                name="nombre"
                                label="Nombre"
                                outlined
                                rounded
                                v-model="newUser.nombre" 
                            ></v-text-field>
                            <v-text-field 
                                :rules="[rules.required]"
                                name="telefono"
                                label="Teléfono"
                                type="number"
                                outlined
                                rounded
                                v-model="newUser.telefono" 
                            ></v-text-field> 
                            <v-text-field 
                                :rules="[rules.required]"
                                @keydown.enter="login" 
                                label="Usuario | Correo Electrónico" 
                                name="login" 
                                v-model="newUser.email" 
                                outlined
                                rounded
                            ></v-text-field>
                            <v-text-field 
                                :rules="[rules.required]"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show1 ? 'text' : 'password'"
                                name="password"
                                label="Contraseña"
                                outlined
                                rounded
                                @click:append="show1 = !show1"
                                @keydown.enter="login" v-model="newUser.password" 
                            ></v-text-field>
                        </v-form>
                        <div class="px-4 py-6">
                            <v-btn rounded block color="#093B99" dark type="submit" @click="register" class="btn btn-primary px-4"><strong>Registrarse</strong></v-btn>
                            <br>
                            <v-btn rounded block color="#093B99" outlined dark type="submit" @click="login" class="btn btn-primary px-4" :to="{ path: '/'}"><strong>Comprar como Invitado</strong></v-btn>
                        </div>
                    </v-card>
                </v-tab-item>

            </v-tabs>
                

            <v-card-actions>
                dkshb,n
            </v-card-actions>
        </v-card>
    </v-card>
</template>

<script>
import axios from "axios";
  export default {
    data: () => ({
        show: false,
        show1: false,
        password: 'Password',
        loading: false,
        selection: 1,
        password: 'Password',
        tab: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        rules: {
            required: value => !!value || 'Campo requerido.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'E-mail inavlido.'
            },
        },
        user:{
            email: '',
            password: '',
        },
        newUser:{
            email: '',
            password: '',
            nombre:'',
            telefono:'',
        }

    }),

    methods:{
        login(){
            this.$store.dispatch('currentUser/loginUser', this.user);
        }, 
        register () {
            axios.post('http://torstdio.site/api/v1/user/guardar',Object.assign(this.user)).then(resp => {
                this.$store.dispatch('currentUser/loginUser', this.user);
            })
        },
    },
  }
</script>

<style scoped>
.theme--light.v-tabs .v-tab--active:hover::before, .theme--light.v-tabs .v-tab--active::before {
    opacity: 0!important;
}
.v-tabs-slider{
    display: none!important;
}
.theme--light.v-tabs-items {
    border-radius: 15px 15px 0px 0px !important;
}
.v-tabs--centered {
    height: 75px!important;
}
</style>