import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Login from '../views/Login.vue'
import App from '../views/App.vue'

const routes = [
  {
    path: '/',
    name: 'App',
    component: App
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/productos/:id',
    name: 'Productos',
    component: () => import('../components/productos.vue')
  },
  {
    path: '/carrito',
    name: 'Carrito',
    component: () => import('../components/carrito.vue')
  },
  {
    path: '/plato',
    name: 'Plato',
    component: () => import('../components/plato.vue')
  },
  {
    path: '/pagar',
    name: 'Pagar',
    component: () => import('../components/pagar.vue')
  },
  {
    path: '/gracias',
    name: 'Gracias',
    component: () => import('../components/gracias.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
