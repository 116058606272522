<template>
  <v-sheet style="background-color:#E60E13!important;" class="fill-height overflow-hidden py-6">
    <v-container class="fill-height pb-12">
      <v-row class="px-6">
        <div><v-img style="max-width:150px!important;" class="mr-6" src="http://tacoselguero.mx/wp-content/uploads/2021/03/logo.png"></v-img></div>
        <v-divider style="border:none!important;" ></v-divider>


        <v-btn v-if="currentUser.name==undefined" dark text class="pa-0" :to="{ path: '/login'}" >
          <v-icon small class="mr-2">mdi-account</v-icon> Iniciar Sesión
        </v-btn>
        <v-btn v-else dark class="pa-0" text @click.stop="drawer = !drawer" >
          <v-icon small class="mr-2">mdi-menu</v-icon> Mi cuenta
        </v-btn>


      </v-row>
      <div v-if="sucursales.length>0" class="px-6 mt-8 mb-4" style="margin-bottom:-30px; color:white; font-size:19px;">Ordena a tu sucursal mas cercana</div>
      <div v-else class="px-6 mt-8 mb-4" style="text-align:center; margin-bottom:-30px; color:white; font-size:19px;">Nuestras sucursales estan cerradas, vuelve pronto.</div>        
        <v-row >
          <v-col cols="6" v-for="(sucursal,k) in sucursales" :key="k">
            <v-card :to="{ path: '/productos/'+ sucursal.id}" class="mx-auto mb-6 elevation-6" outlined>
              <v-img class="ma-1" :src="sucursal.foto"></v-img>
              <v-card-title style="font-size:18px;" class="py-0">
                {{sucursal.nombre}}
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
    </v-container>
    <!-- sidebar -->
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list-item style="background:#001e7a;" class="pt-12 pb-6">
        <v-list-item-avatar style="background-color:#e60f14!important;" v-if="currentUser.name!=undefined">
          <div style="color:white; text-transform:uppercase;">{{currentUser.name.slice(0, 2)}}</div>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title style="color:white;">{{currentUser.name}}</v-list-item-title>
          <v-list-item-subtitle style="color:white;">{{currentUser.email}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list dense>
        <!--v-list-item class="py-2 px-6" to="/conceptos">
          <v-list-item-icon>
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Historial de Pedidos</v-list-item-title>
          </v-list-item-content>
        </v-list-item-->
      </v-list>
      <div>
        <v-divider></v-divider>
        <v-list class="pb-0" dense>
          <v-list-item @click="logout()">
            <v-list-item-icon dark>
              <v-icon> mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Cerrar Sesión</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
    <!-- absolute -->
    <div style="position: fixed; left: 50%; bottom: -10px; transform: translate(-50%, -50%); margin: 0 auto; width:90%; border-radius: 30px!important; background: rgb(255 255 255 / 0.21);">
        <v-row class="text-center px-4 py-2" style="color:white;">
          <v-col href="https://tacoselguero.mx/sucursales/" style="cursor:pointer;" cols="6">
            <v-icon dark>mdi-map-marker</v-icon>
            <br>Ubica tu sucursal
          </v-col>
          <v-col href="https://tacoselguero.mx/sucursales/" style="cursor:pointer;" cols="6">
            <v-icon small dark></v-icon><v-icon dark>mdi-phone</v-icon>
            <br>Llámanos!
          </v-col>
        </v-row>
    </div>
   
  </v-sheet>
</template>
<script>
  export default {
    data () {
      return {
        drawer: null,
        items: [
          { title: 'Home', icon: 'mdi-view-dashboard' },
          { title: 'About', icon: 'mdi-forum' },
        ],
      }
    },
    methods:{
      logout(){
        this.$store.dispatch('currentUser/logoutUser')
      },
    },
    created(){
      this.$store.dispatch('currentUser/getUser')
      this.$store.dispatch('sucursal/getSucursales')
    },
    mounted(){
      Echo.channel('test')
          .listen('Test', (e) => {
            this.$store.dispatch('sucursal/getSucursales')
          })
    },
    computed:{
      sucursales(){
        return this.$store.state.sucursal.sucursales.filter(sucursal=>sucursal.status=='activo')
      },
      currentUser:{
        get(){
          return this.$store.state.currentUser.user
        }
      },
    },
  }
</script>
<style scoped>
  .v-card > *:first-child:not(.v-btn):not(.v-chip), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip) {
    height: 100px;
  }
</style>