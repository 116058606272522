import axios from "axios";
const state = {
    colonias:[]
};
const getters = {};

const actions = {
    getColonias( {commit} ){
        axios
        .get("https://torstdio.site/api/v1/colonia/all")
        .then(response => {
            commit('setColonias', response.data);
        });
    }
};

const mutations = {
    setColonias(state, data){
        state.colonias = data;
    }
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}