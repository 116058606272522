
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
const state = {
    platos:[]
};
const getters = {
};
const actions = {
    addItem(context, item) {
        context.commit("ADD_Item", item);
    },
    editItem(context, item, index) {
        context.commit("EDIT_Item", item, index);
    },
    removeItem(context, index) {
        context.commit("REMOVE_Item", index);
    },
    resetItem(context) {
        context.commit("RESET_Item");
    },
};
const mutations = {
    ADD_Item(state, item) {
        state.platos.push(item);
    },
    EDIT_Item(state, item, index) {
        state.platos.splice(index, item);
    },
    REMOVE_Item(state, index) {
        state.platos.splice(index, 1);
    },
    RESET_Item(state){
        state.platos = []
    }
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};