import axios from "axios";
const state = {
    pedidos:[]
};
const getters = {};

const actions = {
    getPedidos( {commit}, id ){
        axios
        .get("https://torstdio.site/api/v1/pedido/mio")
        .then(response => {
            commit('setPedidos', response.data);
        });
    }
};

const mutations = {
    setPedidos(state, data){
        state.pedidos = data;
    }
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}